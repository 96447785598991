import tableState from "../../templates/table/state";

const displayedTimeframe = "M";

export default {
  ...tableState(),
  website: {},
  timeframeMetric: {},
  displayedTimeframe: displayedTimeframe
};
